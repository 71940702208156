import locations from "./locations.json"

export const CATEGORIES = {
  S: "store",
  B: "bar",
  D: "online",
}

export const MARKER = {
  [CATEGORIES.S]: "/img/pin1.svg",
  [CATEGORIES.B]: "/img/pin2.svg",
  [CATEGORIES.D]: "/img/pin3.svg",
}

export default locations
