import React, { useState, useRef } from "react"
import { useTranslation } from "react-i18next"
import GoogleMapReact from "google-map-react"
import HeroImg from "../../components/HeroImg/HeroImg"
import MainLayout from "../../layouts/MainLayout"
import mapStyles from "../../map-style.json"
import "./where-to-buy.scss"
import locations, { CATEGORIES, MARKER } from "../../locations"
import { GOOGLE_API_KEY } from "../../contants"
import useOutsideClick from "../../hook/click-outside"
import useGeoLocation from "../../hook/geolocation"

const createMapOptions = map => ({
  styles: mapStyles,
  clickableIcons: false,
})

const Marker = ({ src, name, address, info }) => {
  const ref = useRef()
  const [visible, setVisible] = useState(false)

  useOutsideClick(ref, () => {
    if (visible) {
      setVisible(false)
    }
  })

const [city, city2] = address?.split(',');

  return (
    <div
      style={{
        position: "absolute",
        top: "-25px",
        left: "-25px",
        zIndex: visible ? 1 : 0,
        transform: "translateY(-50%)",
      }}
      ref={ref}
    >
      {visible && (
        <div className="marker">
          <p className="name">{name}</p>
          <p className="address">{city}</p>
          <p className="city">{city2}</p>
          {info && <a href={info} target="_blank" className="website">Website</a>}
          <div className="arrow"></div>
        </div>
      )}
      <img onClick={() => setVisible(true)} src={src} alt="marker" />
    </div>
  )
}

const WhereToBuy = () => {
  const { t } = useTranslation()
  const [selectedCategory, setCategory] = useState(CATEGORIES.S)
  const geolocation = useGeoLocation()
  let center = { lat: 44.812062, lng: 20.455687 }
  let zoom = 10

  if (selectedCategory === null && geolocation.error === null) {
    center = { lat: geolocation.latitude, lng: geolocation.longitude }
    zoom = 13
  }

  const markers = () => {
    const result = locations.map(location => {
      if (selectedCategory) {
        if (location.category !== selectedCategory) return null
        return (
          <Marker
            key={location.id}
            lat={location.lat}
            lng={location.lng}
            src={MARKER[location.category]}
            name={location.name}
            address={location.address}
            info={location.info}
          />
        )
      } else {
        return (
          <Marker
            key={location.id}
            lat={location.lat}
            lng={location.lng}
            src={MARKER[location.category]}
            name={location.name}
            address={location.address}
            info={location.info}
          />
        )
      }
    })
    return result
  }

  // Re-center map when resizing the window
  const bindResizeListener = (map, maps, bounds) => {
    maps.event.addDomListenerOnce(map, "idle", () => {
      maps.event.addDomListener(window, "resize", () => {
        map.fitBounds(bounds)
      })
    })
  }

  // Return map bounds based on list of places
  const getMapBounds = (map, maps, places) => {
    const bounds = new maps.LatLngBounds()

    places.forEach(place => {
      bounds.extend(new maps.LatLng(place.lat, place.lng))
    })
    return bounds
  }

  const apiIsLoaded = (map, maps, places) => {
    // Get bounds by our places
    // const bounds = getMapBounds(map, maps, places)
    // Fit map to bounds
    // map.fitBounds(bounds)
    // Bind the resize listener
    // bindResizeListener(map, maps, bounds)
  }

  const selectCategory = category => {
    setCategory(category)
  }

  return (
    <MainLayout metaTitle={t("where-to-buy:metaTitle")}>
      <>
        <HeroImg
          customClass="where-to-buy-hero"
          subtitle={t("where-to-buy:heroSubtitle")}
          title={t("where-to-buy:heroTitle")}
          backgroundImage="bg/where-to-buy-hero.jpg"
        ></HeroImg>
        <div className="where-to-buy">
          <div className="where-to-buy-section-one">
            <h3 className="box-two">{t("where-to-buy:sectionOneTitle")}</h3>
            <p>{t("where-to-buy:sectionOneTextOne")}</p>
            <p>{t("where-to-buy:sectionOneTextTwo")}</p>
            <p>{t("where-to-buy:sectionOneTextThree")}</p>
          </div>

          <div className="map-wrapper">
            <div className="categories">
              <div
                className={selectedCategory === null ? "active" : ""}
                onClick={() => selectCategory(null)}
              >
                {t("where-to-buy:mapTabOne")}
              </div>
              <div
                className={selectedCategory === CATEGORIES.S ? "active" : ""}
                onClick={() => selectCategory(CATEGORIES.S)}
              >
                {t("where-to-buy:mapTabTwo")}
              </div>
              <div
                className={selectedCategory === CATEGORIES.B ? "active" : ""}
                onClick={() => selectCategory(CATEGORIES.B)}
              >
                {t("where-to-buy:mapTabThree")}
              </div>
              <div
                className={selectedCategory === CATEGORIES.D ? "active" : ""}
                onClick={() => selectCategory(CATEGORIES.D)}
              >
                {t("where-to-buy:mapTabFour")}
              </div>
            </div>
            <div className="map">
              <GoogleMapReact
                yesIWantToUseGoogleMapApiInternals
                onGoogleApiLoaded={({ map, maps }) =>
                  apiIsLoaded(map, maps, locations)
                }
                center={center}
                zoom={zoom}
                options={createMapOptions}
                bootstrapURLKeys={{
                  key: GOOGLE_API_KEY,
                }}
              >
                {markers()}
              </GoogleMapReact>
            </div>
          </div>
          <div className="where-to-buy-section-two">
            <h3>{t("where-to-buy:distributors")}</h3>
           <div className="distributers box-three">
             <div>
               <h5>{t("where-to-buy:serbia")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="/Distribucija-Srbija.pdf" target="_blank"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button> </a>
             </div>
             <div>
               <h5>{t("where-to-buy:bh")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="/Uvoznik-BiH.pdf" target="_blank"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button></a>
              </div>
             <div>
               <h5>{t("where-to-buy:slovenia")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="/Uvoznik-Slovenija.pdf" target="_blank"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button></a>
             </div>
             <div>
               <h5>{t("where-to-buy:montenegro")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="/Uvoznici-Crna-Gora.pdf" target="_blank"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button></a>
             </div>
             <div>
               <h5>{t("where-to-buy:croatia")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="#"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button></a>
             </div>
             <div>
               <h5>{t("where-to-buy:australia")}</h5>
               <p className="box-two">{t("where-to-buy:brochure")}</p>
               <a href="/Distribucija-Australija.pdf" target="_blank"><button><img src="/img/download.svg" />{t("where-to-buy:brochure/two")}</button></a>
             </div>
           </div>
           
          </div>
        </div>
     
       
         
      </>
    </MainLayout>
  )
}

export default WhereToBuy
